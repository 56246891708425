import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'
//路由的默认路径
const routes = [
    //pc端
    {
        path: '/',
        name: 'pc',
        component: () => import('../views/pc/Home.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/pc/About.vue'),
    },
    {
        path: '/business',
        name: 'business',
        component: () => import('../views/pc/Business.vue'),
    },
    {
        path: '/succase',
        name: 'succase',
        component: () => import('../views/pc/Succase.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/pc/Contact.vue'),
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('../views/pc/Test.vue'),
    },
    //手机端
    {
        path: '/mobile',
        name: 'mobile',
        redirect: {
            path: '/mobile/home'
        },
        children: [
            {
                path: '/mobile/home',
                name: '/mobile/home',
                component: () => import('@/views/mobile/home/HomeView.vue')
            },
            {
                path: '/mobile/my',
                name: '/mobile/my',
                component: () => import('@/views/mobile/my/MyView.vue')
            },
            {
                path: '/mobile/us',
                name: '/mobile/us',
                component: () => import('@/views/mobile/us/IndexView.vue')
            },
            {
                path: '/mobile/contact',
                name: '/mobile/contact',
                component: () => import('@/views/mobile/contact/IndexView.vue')
            },
            {
                path: '/mobile/business',
                name: '/mobile/business',
                component: () => import('@/views/mobile/business/IndexView.vue'),
                meta: { type: "pc" },
            },
            {
                path: '/mobile/business/v2',
                name: '/mobile/business/v2',
                component: () => import('@/views/mobile/business/IndexView2.vue')
            },
            {
                path: '/mobile/business/v3',
                name: '/mobile/business/v3',
                component: () => import('@/views/mobile/business/IndexView3.vue')
            },
            {
                path: '/mobile/business/v4',
                name: '/mobile/business/v4',
                component: () => import('@/views/mobile/business/IndexView4.vue')
            },
            {
                path: '/mobile/business/v5',
                name: '/mobile/business/v5',
                component: () => import('@/views/mobile/business/IndexView5.vue')
            },
            {
                path: '/mobile/business/v6',
                name: '/mobile/business/v6',
                component: () => import('@/views/mobile/business/IndexView6.vue')
            },
            {
                path: '/mobile/case',
                name: '/mobile/case',
                component: () => import('@/views/mobile/case/IndexView.vue')
            },
        ],
        component: () => import('@/views/mobile/IndexPage.vue')
    },
]
//创建路由对象
const router = createRouter({
    // history: createWebHashHistory(process.env.BASE_URL),
     history: createWebHistory(),
     routes
})
// 全局前置守卫：任何页面的访问都要经过这里
// to：要去哪里的路由信息
// from：从哪里来的路由信息
// next：通行的标志
router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : '多思科技（大连）有限公司';
    //判断是否是手机端
    const isMobile = () => {
        const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag ? true : false;
    }
    if (to.path == '/' && isMobile()) {
        router.replace('/mobile');
    } else if (to.path == '/mobile' || to.path == '/mobile/home' && !isMobile()) {
        router.replace('/');
    } else {
        next()
    }
 })
 export default router;

