import { createI18n } from 'vue-i18n'
import messages from './index.js'

const i18n = createI18n( {
    legacy: false, //处理报错Uncaught (in promise) SyntaxError: Not available in legacy mode (at message-compiler.esm-bundler.js:54:19)
    locale: localStorage.getItem('lang') || "zhcn", // 设置默认语言,注意locale属性~~~~~~~~！
    messages
})
//配置HTML lang
document.querySelector('html').setAttribute('lang', localStorage.getItem('lang') || "zhcn");
export default i18n

