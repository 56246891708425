const en = {
    menu:{
        "home":"Home",
        "aboutUs":"AboutUs",
        "businessSectors":"BusinessSectors",
        "successCases":"SuccessCases",
        "contactUs":"ContactUs",
    },
    messages: {
        "upload": "Upload",
        "compamy": "Duosi Technology(Dalian)",
        "corporation": "Co.,Ltd",
        "slogan": "Professional · Innovative · Responsible · Win-Win",
        "button": 'Learn More',
    },
    companyOverview: {
        "title": "Company Overview",
        "introduction": "DuoSi Technology is an IT system solution company with rich experience and leading technology",
        "textOne": "Since its establishment in 2013, we have experienced a decade of development, standing at the forefront of industry fields, and combining broad market demand, actively studying, digesting, absorbing domestic and foreign advanced management concepts and business models. We have deep technical accumulation in fields such as software quality audit, smart medical, digital government and enterprise, and digital transformation of industrial systems.",
        "textTwo": "The company adheres to technology innovation as its core competitiveness, is committed to providing customers with the most suitable IT application solutions and high-quality system high-availability solutions, and truly solves the problems that customers encounter in IT applications, promotes the development of customer IT applications, and creates huge value for customers.",
        "corporateVision": 'Corporate Vision',
        "corporateVisionT": 'To become a technology service company respected by society, customers, and employees.',
        "corporateMission": 'Corporate Mission',
        "corporateMissionT": 'To bring benefits and value to customers through information technology.',
        "corporateValues": 'Corporate Values',
        "corporateValuesT": 'Professional·Innovative',
        "corporateValuesTS": 'Responsible·Win-Win',
    },
    homeBusinessSector: {
        "title": "Business Sectors",
        "introduction": "The company has deep technical accumulation in areas such as software quality audit, smart medical, and digital government and enterprises",
        businessSectorMenuOne: {
            "title": "Software Quality Audit Based on Android",
            "one": "App listing certification service on Android platform",
            "two": "Quality control of wearable device software systems",
            "three": "Quality control of mobile device software systems",
            "four": "Quality control of IoT device software systems",
            "five": "Quality control of vehicle system software",
            "six": "Quality control of smart home device software systems",
        },
        businessSectorMenuTwo: {
            "title": "Smart Healthcare IT Solutions",
            "one": "Medical Information Big Data Platform",
            "two": "Outpatient Doctor Workstation",
            "three": "Patient Information Panoramic Query System",
            "four": "Medical Technology Data Integration Platform",
            "five": "Domestic Upgrading and Transformation Solution for Medical Institutions' IT Systems",
            "six": "",
        },
        businessSectorMenuThree: {
            "title": "Digitalization of Government and Enterprise Systems",
            "one": "OA System",
            "two": "LAN-based Remote Conference System",
            "three": "Smart Party Building System",
            "four": "Electronic Archive System",
            "five": "Enterprise Skills Comprehensive Training and Examination Management Platform",
            "six": "Task Management System",
        },
        businessSectorMenuFour: {
            "title": "Digital Transformation of Industrial Systems",
            "one": "Integrated Inspection Platform for Power Facilities",
            "two": "Intelligent Statistical Analysis Platform for Open-pit Mining Production",
            "three": "Intelligent Dispatch Platform for Mining Vehicles",
            "four": "Mining Vehicle Control and Warning Platform",
            "five": "Metallurgical Energy EMS Management Platform",
            "six": "Chemical Process Control Information System",
        },
        businessSectorMenuFive: {
            "title": "Tools and Services",
            "one": "Digital Warehouse and Big Data Platform",
            "two": "Data Visualization Analysis Platform",
            "three": "Data Exchange and Sharing Center",
            "four": "Live Streaming Audio and Video Service",
            "five": "Intelligent Jewelry Appraisal Platform",
            "six": "Augmented Reality Virtual Training System",
            "seven":"Blockchain Data Platform",
        },
        businessSectorMenuSix: {
            "title": "IT System and Software Custom Development",
            "one": "Application Software",
            "two": "Mobile Apps",
            "three": "WeChat Mini Programs",
            "four": "VR, AR",
            "five": "Domestic Upgrading and Transformation of IT Information Systems",
            "six": "Blockchain, Web3.0, and Dapp Development",
        },
    },
    homeImplementationProcess: {
        "title": "Implementation Process",
        "introduction": "Strict project implementation process, each link produces corresponding deliverables",
        "matterTitle": "Matters",
        "matterOne": "Project Initiation",
        "matterTwo": "Requirement Analysis",
        "matterThree": "UI Design",
        "matterFour": "Development",
        "matterFive": "Testing",
        "matterSix": "Delivery",
        "matterSeven": "Customer Callback",
        "matterEight": "Project Summary",
        "achievementTitle": "Deliverables",
        achievementOne: {
            "one": "Project Initiation Document"
        },
        achievementTwo: {
            "one": "Prototype",
            "two": "Requirement Document",
            "three": "Test Cases",
        },
        achievementThree: {
            "one": "UI Design Diagram"
        },
        achievementFour: {
            "one": "Project Plan",
            "two": "Progress Weekly Report",
            "three": "Interface Document",
        },
        achievementFive: {
            "one": "Test Report",
            "two": "User Manual",
        },
        achievementSix: {
            "one": "Acceptance Confirmation Form"
        },
        achievementSeven: {
            "one": "Callback Survey Form"
        },
        achievementEight: {
            "one": "Project Summary Form"
        },
    },
    homeSuccessCases: {
        "title": "Success Cases",
        "introduction": "Committed to providing customers with the most suitable IT application solutions and high-quality system high availability solutions",
    },

    about:{
        "title": "About Us",
        "introduction": ""
    },
    enterpriseSize:{
        "title": "Enterprise Size",
        "introduction": "Our company is headquartered in Dalian, Liaoning Province and has a well-established business network and technical service system in the region. In 2019, Osaka branch was established, In 2020, we established a branch office in Shenyang to expand our business scope.",
        "dl": "Dalian Headquarters",
        "dl_address": "Block B, Entrepreneurship Building, High-tech Zone, Dalian",
        "sy": "Shenyang Branch",
        "sy_address": "Block E17B, International Software Park, Hunnan District, Shenyang",
        "text": "We have ambitious plans to expand our business into the fields of artificial intelligence, intelligent manufacturing, industrial internet, and WEB3, providing customers with more diverse solutions.",
        "jp":"Osaka Branch",
        "jp_address":"〒542-0081 Osaka-shi Chuo-ku Minamisenba 1-11-9 Okubori Yachiyo10F",
    },
    honoraryQualifications:{
        "title": "Honorary Qualifications",
        "introduction": "Over the past decade, our company has obtained multiple enterprise qualifications and certifications, including the National Intellectual Property Management System Certification, National Quality Management System Certification, and National High-tech Enterprise Certification. We have also acquired over thirty software copyrights for our products."
    },
    corporateStructure:{
        "title": "Corporate Structure",
        "introduction": "We have a team of over 80 professional technical talents who can quickly respond to customer demands and provide comprehensive technical services, helping clients address market competition challenges. Our success lies in adhering to the values of customer-centricity, technological leadership, teamwork, and integrity. We continuously pursue technological innovation and enhance customer value, maintaining a high level of professionalism and dedication.",
        "general_office":"General Office",
        "treasurer":"Finance Supervisor",
        "personnel_manager":"Personnel Supervisor",
        "marketing_department":"Marketing Department",
        "sales_director":"Sales Director",
        "regional_manager":"Regional Manager",
        "production_department":"Product Department",
        "product_manager":"Product Manager",
        "3d_modeler":"3D Modeling Engineer",
        "ui_designer":"UI Designer",
        "development_department":"R&D Department",
        "architect":"Architect",
        "java_engineer":"JAVA Engineer",
        "app_engineer":"APP Engineer",
        "c++_engineer":"C++ Engineer",
        "project_manager":"Project Manager",
        "front_end_engineer":"Front-end Engineer",
        "unity_engineer":"Unity Engineer",
        "test_engineer":"Test Engineer",
        "implementation_department":"Implementation Department",
        "implementation_service_engineer":"Implementation Service Engineer",
    },
    business:{
        "title": "Business Sectors",
        "introduction": ""
    },
    businessOne:{
        "title": "Android-based Software Quality Audit",
        one: {
            "left": "As a professional provider of Android software quality testing services, we conduct internationally recognized usability certification testing for all software products running on the Android system. We provide accurate and reasonable improvement suggestions to help customers obtain authoritative certifications from major institutions, thereby maximizing support for product promotion and helping clients realize the monetization of their product's technical value.",
            "right_one_title": "Compatibility Test Suite (CTS)",
            "right_one_text": "The Compatibility Test Suite, also known as Google Compatibility Certification, ensures that certified devices can correctly run all applications developed for Android.",
            "right_two_title": "Android Enterprise Recommended (AER)",
            "right_two_text": "Android Enterprise Recommended selects devices and service providers that meet Google's strict enterprise requirements. When a mobile device or service carries this mark, it can be safely used in enterprises.",
            "right_three_title": "ARCore OEM Device Certification",
            "right_three_text": "ARCore is Google's augmented reality experience platform. With different APIs, ARCore enables your phone to perceive the environment, understand the world, and interact with information. Some APIs can be used for both Android and iOS to achieve shared AR experiences."
        },
        two: {
            "right_text": "For software product quality audits, a scientific process model is essential to ensure more accurate and objective audit results. A well-defined audit process can significantly improve audit efficiency, enabling faster and more precise identification of defects and issues in products. In this regard, we have focused on designing and developing the W Quality Audit Process Model, which emphasizes synchronous quality control with the product development process. It strictly controls quality during the development phase, reducing the workload of defect fixing and improving development efficiency. We also conduct objective quality assessments and risk issue warnings based on system requirement documents and specifications during the requirements phase, helping identify defects that may affect quality hidden in the product planning stage."
        },
        three:{
            "text": "Our company can undertake any software devices running on the Android system, including mobile communication device software, smart wearable device operating systems, in-vehicle intelligent systems, and IoT devices. We provide comprehensive and objective functional quality inspections based on the product's usage scenarios, network operating environment, and functionality. Additionally, we offer authentic and effective testing reports and solutions. We can also conduct testing and certification through major Android certification institutions, providing customers with quality testing reports recognized by these institutions, facilitating the smooth promotion of their products."
        },
        four: {
            "one_title": "Mobile Communication Device Software",
            "one_text": "Smartphone operating systems, apps, tablet operating systems, Android system components, etc.",
            "two_title": "Smart Wearable Device Systems",
            "two_text": "Smartwatch operating systems, sports bracelet operating systems, wearable device components, etc.",
            "three_title": "In-vehicle Intelligent Systems",
            "three_text": "In-vehicle infotainment systems, car control panel operating systems, etc.",
            "four_title": "IoT Systems",
            "four_text": "Smart TV operating systems, smart home software systems, self-service machine systems, hospital self-service device systems, engineering control device software systems, etc."
        }
    },
    businessTwo:{
        "title": "Smart Medical IT Solutions",
        one:{
            "left":"Based on national industry policies and relevant standards and specifications in the field of national health informationization, and with more than ten years of industry experience and accumulation as well as leading technical strength, we adhere to the customer-centered concept, focus on the pressures and challenges faced by customer business development, and integrate cutting-edge information technologies and architectural concepts such as cloud, big data, mobility, intelligence, information security, and middle platform. We provide hospitals with one-stop solutions for smart hospital informationization construction, including customer relationship management, hospital core business, medical management, academic discipline construction, operation management, office automation, medical group construction, internet hospitals, hospital information platforms, IT operation and maintenance, consulting and planning, etc., empowering customers to continuously improve patient experience, operation experience, management mode, academic discipline construction system, and operation system, and helping hospitals achieve their IT strategy.",
        },
        two:{
            "right_title":"Medical Informationization Big Data Platform",
            "right_text_one":"The solution of Medical Informationization Big Data Platform is",
            "right_text_two":"based on medical industry data technology standards such as HL7, with a self-developed medical ESB data service bus engine as the core, referencing standard technology frameworks and structures to integrate various medical informationization systems within the hospital through SOA technology.",
            "right_text_three":"It achieves informationization data integration of all businesses and unified distribution through the platform while not changing the existing information system business logic. Thus,",
            "right_text_four":"interconnecting information among various informationization application systems within the hospital.",
            "right_text_five":"In addition, the platform should comply with relevant information standards and specifications formulated by national health medical institutions. It can achieve data connectivity, information sharing, and business-standardized data exchange between different information systems and departments.",
        },
        three: {
            "left_title":"Outpatient Doctor Workstation",
            "left_text_one":"To solve the problems and drawbacks in the traditional information construction of hospitals, such as inaccurate and incomplete information display, incomplete historical information, complex operation for order creation, poor convenience, difficult maintenance, and incomplete business linkages, and to meet the increasing business and management demands. Focusing on improving business linkage, cross-category group creation, and electronic medical record template creation, the \"Outpatient Doctor Workstation System\" is built to meet business demands based on the actual hospital environment and benchmarking national-level smart hospitals.",
            "left_text_two":"The system starts with the daily work of outpatient doctors and focuses on improving the efficiency of doctors in their daily work; integrating the functions of current system plug-ins to achieve one-stop operation; clarifying permissions and providing refined service interfaces; establishing a patient information chain spanning the pre-hospital, in-hospital, and post-hospital stages; configuring flexible and open data interfaces and verification alerts to facilitate medical workers in quickly and accurately querying patient-related information; adopting semi-structured electronic medical records and template creations; and integrating the functions of examination and inspection requests. The system should have the characteristics of easy maintenance, heavy service, and light client."
        },
        four: {
            "left_title": "Panoramic Patient Information Query System",
            "left_text": "The panoramic patient query system will statistically analyze and calculate the data obtained by the data center, and provide multi-dimensional medical information query services to the outside world through precise composite searches, including medical imaging, patient records, diagnosis and treatment data and other data information queries. At the same time, relying on the policy orientation of the medical integration alliance, the big data query system supports cloud imaging cross-institutional queries, remote patient consultations, patient information sharing and other functions.",
            "right_title": "Medical Technology Data Integration Platform",
            "right_text": "The medical technology data integration platform is built around the self-developed WSB service bus engine, and adopts SOA microservice technology to interact with other information systems in the hospital in the form of i data center, and supports cleaning, synchronization and archiving of acquired data and other functions.",
        }
    },
    businessThree:{
        "title": "Digitalization of Government and Enterprise Systems",
        one: {
            "text": "In the field of government affairs, we are committed to deep innovation integration of digital technology and government services. Our aim is to continuously improve the standardization, networking, and intelligentization of government services, promoting the development of the 'Internet+Government Services'system that enables overall linkage, departmental collaboration, and one-stop access. We are dedicated to building a comprehensive government integration platform that provides services such as information disclosure, government internal search, and multi-rule integration. This platform effectively supports large-scale government services involving various subjects, such as the development of 'Internet+Government Services' and 'Internet+Regulatory Services' as core elements, covering sectors including development and reform, industry and information technology, human resources and social security, taxation, and the public."
        },
        two: {
            "title": "LAN-based Remote Conference System",
            "text_one": "LAN-based video conferencing is a new type of conference method based on LAN streaming media systems in the era of networking. It offers various functions such as data and image transmission, recording, storage, and information communication, providing unparalleled convenience in daily work compared to traditional meetings.",
            "text_two": "Especially for government and enterprise organizations, schools, institutions, and departments that have internal confidentiality requirements and special restrictions on network environments, LAN-based streaming media system construction has become an important part of the modern office construction for government and enterprise institutions."
        },
        three: {
            "title": "Smart Party Building Service System",
            "text": "The Smart Party Building Service System provides support for the development of party building work in government and enterprise settings. It includes features such as party work process organization, task management, guideline learning, and comprehensive inquiries. The module content can be maintained through the management end. This system provides information technology support for party building work in government and enterprise organizations, improving the efficiency of daily party building work and reducing personnel costs."
        },
        four: {
            "title": "Comprehensive Skills Training and Examination Platform",
            "text": "The government and enterprise training system aims to improve employees' knowledge, skills, work methods, work attitudes, and the value system of their work, thereby unleashing their full potential. It enhances individual and organizational performance, promotes continuous improvement for organizations and individuals, and achieves the dual development of organizations and individuals. This system involves planned and systematic training and examination tasks to enhance employees' qualities, capabilities, work performance, and contributions to the organization, facilitating the continuous progress of organizations and individuals through the improvement of their skills."
        }
    },
    businessFour:{
        "title": "Digital Transformation of Industrial Systems",
        one: {
            "text": "Under the overall goal of 'Made in China 2025,' the trend of digital transformation in industrial systems is inevitable. Based on the general principles of national industrial intelligent transformation and our own technical strength, we use new technologies such as the Internet of Things, big data, and artificial intelligence to help customers achieve real-time management, self-perception of information depth, intelligent optimization of decision-making, and precise control in industrial systems. We provide different information system solutions to assist in achieving accurate process control and implementation management in industrial systems. This helps customers solve cost and time issues while gradually advancing the manufacturing industry into the era of intelligence."
        },
        two: {
            "title": "Comprehensive Inspection Platform for Power Facilities",
            "text_one": "The comprehensive inspection platform for power facilities combines unmanned aerial vehicles (UAVs), sensors, advanced image analysis technology, and big data technology. It detects safety hazards in overhead power lines and substations, such as deformation, rust, poor contact, and leakage. The platform integrates intelligent data analysis, structured statistics, and visualization display into an integrated intelligent platform system.",
            "text_two": "In addition to inspection data, the platform is specially designed for the management and task allocation of inspectors and inspection subjects. It connects the business line from inspection task assignment to data collection, statistical analysis, and early warning. This maximizes the efficiency of power facility inspections and reduces inspection-related safety risks."
        },
        three: {
            "title": "Digital Production Operation and Control System",
            "text_one": "The digital production operation and control system is a comprehensive control system that enables digital production and operation in the manufacturing industry. It primarily addresses practical issues in the manufacturing industry, such as difficulties in controlling production process progress, low management efficiency, and errors in warehouse operations.",
            "text_two": "By implementing information technology in key areas such as production processes, quality control, and warehouse management, the system helps enterprises improve production management efficiency and optimize warehouse management processes. Additionally, based on big data technology, it achieves end-to-end product quality traceability, facilitating the digital transformation of industrial manufacturing."
        }
    },
    businessFive:{
        "title": "Tools and Services",
        one: {
            "title": "Data Warehouse and Big Data Platform",
            "text": "The big data platform is compatible with various operating systems. It not only supports intelligent recognition and processing of imported data but also provides powerful visual dashboard editing features. This includes a variety of data source formats, various types of visualization components, flexible project delivery methods, and optimized software user experience. The system integrates the functional requirements of data analysis and decision support from various industries, meeting the needs of end users in enterprise-level reporting, data visualization analysis, self-exploration analysis, data mining modeling, AI-powered analytics, and other big data analysis requirements."
        },
        two: {
            "title": "Data Visualization and Analysis BI Platform",
            "text": "The big data platform is compatible with various operating systems. It not only supports intelligent recognition and processing of imported data but also provides powerful visual dashboard editing features. This includes a variety of data source formats, various types of visualization components, flexible project delivery methods, and optimized software user experience. The system integrates the functional requirements of data analysis and decision support from various industries, meeting the needs of end users in enterprise-level reporting, data visualization analysis, self-exploration analysis, data mining modeling, AI-powered analytics, and other big data analysis requirements."
        },
        three: {
            "title": "Intelligent Gemstone Identification Platform",
            "text_one": "The intelligent gemstone identification platform is a digitalgemstone identification system developed specifically for the jewelry industry. It addresses the subjectivity and lack of clear standards in traditional manual gemstone identification.",
            "text_two": "The system quantifies the grading data of colored gemstones, helping improve the authority and objectivity of gemstone identification while reducing personnel costs in the gemstone identification process."
        }
    },
    businessSix:{
        "title": "Custom Development of IT Systems and Software",
        one: {
            "title": "Professional Services Empowering Customer Business Development",
            "text": "By establishing a comprehensive 'Onsite + Nearshore + Offshore' delivery service model, we provide customers with full-lifecycle software development/maintenance services from device-side to server-side across the country."
        },
        two: {
            "one_t": "Application Software",
            "two_t": "APP",
            "three_t": "WeChat Mini Programs",
            "four_t": "VR, AR",
            "five_t": "Domestication of Information Systems",
            "six_t": "Blockchain Dapps"
        }
    },
    successCases:{
        "title": "Success Cases",
        one: {
            "title": "A Top-level Grade A Hospital",
            "introduction": "Comprehensive collaboration on hospital informatization project for a top-level grade A hospital",
            "text_one": "Hospital Data Center Platform",
            "text_two": "Hospital Electronic Medical Record Management System",
            "text_three": "Hospital Outpatient Doctor Workstation",
            "text_four": "Hospital Medical Staff Assessment Management Platform",
            "text_five": "Hospital Video Conference System"
        },
        two: {
            "title": "An International Gemstone Laboratory",
            "introduction": "Founded in July 1980 in Los Angeles, the gemstone laboratory has been dedicated to the research, identification, popularization, and promotion of colored gemstones. It has extensive experience and significant achievements in establishing a standardized market system for colored gemstones.",
            "text_one": "Colored Gemstone Laboratory Testing System",
            "text_two": "Rough Stone Trading System"
        },
        three: {
            "title": "A Power Supply Company of State Grid",
            "introduction": "The power supply company is a major state-owned power enterprise responsible for the planning, construction, operation, and power supply in a certain city. It covers an area of 12,600 square kilometers, serving 2.84 million customers and a population of 6.13 million. The company has 16 functional departments, 13 power supply branches, 14 professional work zones, and multiple subsidiary enterprises.",
            "text_one": "Smart Party Building System",
            "text_two": "Party Construction Standardization All-in-One Project"
        }
    },
    contact:{
        "title": "Contact Us",
        "contacts": "Contact",
        "contacts_name": "Mr. Yang",
        "telephone": "Telephone",
        "phone": "155-4251-7211",
        "email":"E-mail",
        "emailnum":"yangjian@duostec.com",
    },
    certificate_title:{
        "title_one":"High-tech enterprise certificate",
        "title_two":"Computer software copyright registration certificate",
        "title_three":"Quality management system certification",
    },
    img:{
        "frameworkImg":"3",
    }
}
export default en
