import { createApp } from 'vue'
import App from './App.vue'
import router from './router' //路由

//导入 element-plus
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
//vant
import Vant from 'vant'
import { Col, Row, Image as VanImage } from 'vant';
//全屏滚动 vue-fullpage.js
import VueFullpage from 'vue-fullpage.js'
//多语言国际化
import I18n from '@/lang/i18n.js'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import "amfe-flexible/index.js";

//导入样式
// import '@/assets/css/fullpage.css'
import "element-plus/dist/index.css";
import '@/assets/font/font.css'
import '@/assets/css/pc/transition.css'


const app = createApp(App);
app.use(ElementPlus);
// app.use(VueFullpage);
app.use(router);
app.use(Vant);
app.use(VueAwesomeSwiper);
app.use(Col);
app.use(Row);
app.use(VanImage);
app.use(I18n);
app.mount('#app')
