<template>
    <router-view />
</template>
<style>
#app {
    //font-family: Avenir, Helvetica, Arial, sans-serif;
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
    text-align: center;
    //color: #2c3e50;
}

body {
    margin: 0;
}

* {
    box-sizing: border-box;
}
</style>
<script setup>
</script>
